import { mapActions, mapGetters } from 'vuex';

//---helpers
import { globalLoader } from '@/helpers/variables';
import { BLOG_CONTENT_TYPE } from '@/helpers';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import ContentBlock from '@/components/custom/ContentBlock/index.vue';

export default {
  name: 'About',
  mixins: [dynamicHead],
  components: { ContentBlock, BreadcrumbsSimple },
  props: {},
  data() {
    return {
      BLOG_CONTENT_TYPE,
      aboutUsData: null
    };
  },
  created() {
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    })
  },
  methods: {
    ...mapActions({
      getAboutUs: 'pages/GET_ABOUT_US',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      promiseCollection.push(
        this.getAboutUs().then((result) => {
          this.aboutUsData = result;
        })
      );
      promiseCollection.push(
        this.getMetaData('about-us').then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
